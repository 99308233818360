// Generated by Framer (d275c2b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["HE43SmLwT", "JvoO1FXIL"];

const serializationHash = "framer-Q4NXj"

const variantClassNames = {HE43SmLwT: "framer-v-fngpyl", JvoO1FXIL: "framer-v-rfeayi"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Desktop_Title: "JvoO1FXIL", Desktop: "HE43SmLwT"}

const getProps = ({content, height, id, width, ...props}) => { return {...props, dWgMowc7Q: content ?? props.dWgMowc7Q ?? "Проект", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "HE43SmLwT"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;content?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, dWgMowc7Q, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "HE43SmLwT", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-fngpyl", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"HE43SmLwT"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({JvoO1FXIL: {"data-framer-name": "Desktop_Title"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0Z1dHVyYSBQVCBNZWRpdW0=", "--framer-font-family": "\"Futura PT Medium\", \"Futura PT Medium Placeholder\", sans-serif", "--framer-font-size": "17px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-b740f1d8-819f-424e-8ed4-13557f40bfa1, rgb(20, 34, 81)))"}}>Проект</motion.p></React.Fragment>} className={"framer-azxu69"} fonts={["CUSTOM;Futura PT Medium"]} layoutDependency={layoutDependency} layoutId={"fiVT7pada"} style={{"--extracted-r6o4lv": "var(--token-b740f1d8-819f-424e-8ed4-13557f40bfa1, rgb(20, 34, 81))"}} text={dWgMowc7Q} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({JvoO1FXIL: {children: <React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0Z1dHVyYSBQVCBIZWF2eQ==", "--framer-font-family": "\"Futura PT Heavy\", \"Futura PT Heavy Placeholder\", sans-serif", "--framer-font-size": "17px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-b740f1d8-819f-424e-8ed4-13557f40bfa1, rgb(20, 34, 81)))"}}>Проект</motion.p></React.Fragment>, fonts: ["CUSTOM;Futura PT Heavy"], text: undefined}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Q4NXj.framer-l0b4rw, .framer-Q4NXj .framer-l0b4rw { display: block; }", ".framer-Q4NXj.framer-fngpyl { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: wrap; gap: 10px; height: 26px; justify-content: flex-start; padding: 0px; position: relative; width: min-content; }", ".framer-Q4NXj .framer-azxu69 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Q4NXj.framer-fngpyl { gap: 0px; } .framer-Q4NXj.framer-fngpyl > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Q4NXj.framer-fngpyl > :first-child { margin-left: 0px; } .framer-Q4NXj.framer-fngpyl > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 26
 * @framerIntrinsicWidth 51
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"JvoO1FXIL":{"layout":["auto","fixed"]}}}
 * @framerVariables {"dWgMowc7Q":"content"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerzk09Cg3UM: React.ComponentType<Props> = withCSS(Component, css, "framer-Q4NXj") as typeof Component;
export default Framerzk09Cg3UM;

Framerzk09Cg3UM.displayName = "Analytics - Cell";

Framerzk09Cg3UM.defaultProps = {height: 26, width: 51};

addPropertyControls(Framerzk09Cg3UM, {variant: {options: ["HE43SmLwT", "JvoO1FXIL"], optionTitles: ["Desktop", "Desktop_Title"], title: "Variant", type: ControlType.Enum}, dWgMowc7Q: {defaultValue: "Проект", displayTextArea: false, title: "Content", type: ControlType.String}} as any)

addFonts(Framerzk09Cg3UM, [{explicitInter: true, fonts: [{family: "Futura PT Medium", source: "custom", url: "https://framerusercontent.com/assets/cnB7elCjJUflMpjiDVgI0XPy44U.woff2"}, {family: "Futura PT Heavy", source: "custom", url: "https://framerusercontent.com/assets/HeEQTkbQL9r2MgpyOAn1G8wf3A.woff2"}]}], {supportsExplicitInterCodegen: true})